<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="8" />
        <div class="top_pic"><img :src="banner" alt=""></div>
        <div class="join_box">
            <div class="join_title">{{joinData.title}}</div>
            <div class="join_content" v-html="joinData.content"></div>
        </div>
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos"  @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    <Tips></Tips>
    </div>
</template>

<script>
    import {joinindex,cateBanner} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
import Tips from "@/components/tips.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame,
            Tips
        },
        data(){
            return{
                JoinModal:false,
                joinData:{},
                banner:''
            }
        },
        created() {
            this.joinindex()
            this.cateBanner()
        },
        methods:{
            joinindex(){
                joinindex().then(res=>{
                    this.joinData = res.data 
                })
            },
            cateBanner(){
                cateBanner({id:8}).then(res=>{
                    this.banner = res.data
                })
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.join_box{
    width: 80%;
    margin: 40px auto;
}
.join_title{
    text-align: center;
    color: #333333;
    font-size: 28px;
}
.join_content{
    margin: 50px 0;
}
</style>